import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiSettings } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import { Divider, Flex, PseudoBox, Text, Image, Badge } from '@chakra-ui/core';
import { FormHandles } from '@unform/core';

import cieloLogo from '../../../../assets/images/cielo_logo.png';
import iugoLogo from '../../../../assets/images/iugu_logo.png';
import mercadopagoLogo from '../../../../assets/images/mercadopago_logo.png';
import pagarmeLogo from '../../../../assets/images/pagarme_logo.png';
import pagseguro from '../../../../assets/images/pagseguro_logo.png';
import paypalLogo from '../../../../assets/images/paypal_logo.png';
import Form from '../../../../components/Form';
import { useLayout } from '../../../../layouts/default';
import { HTTP } from '../../../../shared/constants';
import apiGateway from '../../../../shared/services/apiGateway';

const PaymentCreditCardSettings: React.FC = () => {
    const { changeTitle } = useLayout();
    const navigate = useNavigate();

    const formRef = useRef<FormHandles>(null);

    const [paymentPlatforms, setPaymentPlatforms] = useState([]);

    const logos = {
        CIELO: cieloLogo,
        IUGU: iugoLogo,
        MERCADOPAGO: mercadopagoLogo,
        PAGARME: pagarmeLogo,
        PAYPAL: paypalLogo,
        PAGSEGURO: pagseguro
    };

    const handleChangePlatform = useCallback(platform => {
        const data = {
            ...platform,
            is_enable: !platform.is_enable
        };

        apiGateway
            .put(`/settings/payment_platforms/${platform.id}`, data)
            .then(response => {
                if (response.status === HTTP.STATUS.SUCCESS) {
                    setPaymentPlatforms(oldState => {
                        return oldState.map(state => {
                            if (state.id === platform.id) {
                                return data;
                            }

                            return state;
                        });
                    });
                }
            });
    }, []);

    useEffect(() => {
        changeTitle('Métodos de Pagamento');
        apiGateway.get('/settings/payment_platforms').then(response => {
            setPaymentPlatforms(response.data);
        });
    }, [changeTitle]);

    return (
        <Form ref={formRef as any} onSubmit={() => null}>
            <Flex width="100%" flexDirection="column">
                <Flex width="100%" flexDirection="column">
                    <Flex
                        width="100%"
                        flexDirection="column"
                        backgroundColor="white"
                        px="24px"
                    >
                        <Flex
                            width="100%"
                            py="16px"
                            justifyContent="space-between"
                            flexWrap="wrap"
                        >
                            <Flex width="100%" justifyContent="space-between" flexWrap="wrap">
                                <Flex
                                    width="50%"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                >
                                    <Text mr="16px" fontSize="24px" color="purple.500">
                    Configurarações de Cartão de Crédito
                                    </Text>
                                </Flex>
                            </Flex>

                            <Divider my="0px" />
                            <Flex
                                width="100%"
                                justifyContent="flex-start"
                                alignItems="center"
                                mt="24px"
                            >
                                <Flex width="100%" maxWidth="100%" overflowX="auto">
                                    <Flex width="100%">
                                        {paymentPlatforms.map(platform => (
                                            <Flex
                                                key={platform.id}
                                                width="100%"
                                                maxWidth="192px"
                                                p="8px"
                                                border="1px solid"
                                                borderColor="gray.200"
                                                justifyContent="space-between"
                                                flexDirection="column"
                                                mr="24px"
                                                position="relative"
                                            >
                                                <Flex
                                                    width="100%"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    height="100%"
                                                    py="8px"
                                                >
                                                    <Image width="60%" src={logos[platform.type]} />
                                                </Flex>
                                                <Flex
                                                    width="100%"
                                                    justifyContent="space-between"
                                                    mt="16px"
                                                >
                                                    <Flex
                                                        width="100%"
                                                        flexDirection="column"
                                                        fontSize="12px"
                                                        height="100%"
                                                    >
                                                        <Badge
                                                            width="90%"
                                                            textAlign="center"
                                                            backgroundColor={
                                                                platform.is_enable ? 'green.500' : 'pink.500'
                                                            }
                                                            cursor="pointer"
                                                            color="white"
                                                            py="7px"
                                                            onClick={() => handleChangePlatform(platform)}
                                                        >
                                                            {platform.is_enable
                                                                ? 'Habilitado'
                                                                : 'Desabilitado'}
                                                        </Badge>
                                                    </Flex>
                                                    <Flex>
                                                        <PseudoBox
                                                            title="Configurar"
                                                            py="8px"
                                                            px="8px"
                                                            backgroundColor="purple.500"
                                                            color="white"
                                                            borderRadius="2px"
                                                            cursor="pointer"
                                                            textAlign="center"
                                                            onClick={() => {
                                                                navigate(
                                                                    `/settings/payment_methods/credit_card/${platform.type.toLowerCase()}/${
                                                                        platform.id
                                                                    }`
                                                                );
                                                            }}
                                                        >
                                                            <FiSettings size={16} />
                                                        </PseudoBox>
                                                    </Flex>
                                                </Flex>
                                                {platform.name === 'Pagarme V5' && (
                                                    <Flex position="absolute" top="-4px" right="-2px"  w="40px" h="40px" backgroundColor="green.500" color="white" justifyContent="center" alignItems="center" rounded="sm">
                                                        V5
                                                    </Flex>
                                                )}
                                            </Flex>
                                        ))}
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                        <Divider my="0px" />
                    </Flex>
                </Flex>
            </Flex>
        </Form>
    );
};

export default PaymentCreditCardSettings;

/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import React, { createContext, useCallback, useContext, useState } from 'react';

import { v4 as uuid } from 'uuid';
import IImage from '../data/dtos/IImage';
import ITag from '../data/dtos/ITag';
import IType from '../data/dtos/IType';

interface IAttributes {
    is_enable: boolean;
    is_promotional: boolean;
    is_free_shipping: boolean;
    images: IImage[];
    categories: string[];
    types: IType;
    subtypes: IType;
    tags: ITag;
    variants: any;
}

interface IContextData {
    setEnable: React.Dispatch<React.SetStateAction<boolean>>;
    setPromotional: React.Dispatch<React.SetStateAction<boolean>>;
    setFreeShipping: React.Dispatch<React.SetStateAction<boolean>>;
    addImages: (image: IImage) => void;
    addCategories: (ids: string[]) => void;
    addTypes: (ids: string[], news: string[]) => void;
    addSubtypes: (ids: string[], news: string[]) => void;
    addTags: (ids: string[], news: string[]) => void;
    addVariant: (formRef: any) => void;
    addListVariants: (formRef: any) => void;
    editVariant: (variantName: string, field: string, value: any) => void;
    removeVariants: (variantName: string) => void;
    changeOrderImage: (indexDragged, indexTarget) => void;
    removeImage: (image: IImage) => void;
    clearImages: () => void;
    setCover: (image: IImage) => void;
    getAttributes: () => IAttributes;
}

const ProductFormContext = createContext({} as IContextData);

const ProductFormProvider: React.FC<any> = ({ children }) => {
    const defaultTypesAndTags = {
        ids: [],
        news: []
    };

    const [isEnable, setEnable] = useState(true);
    const [isPromotional, setPromotional] = useState(false);
    const [isFreeShipping, setFreeShipping] = useState(false);
    const [images, setImages] = useState<IImage[]>([]);
    const [categoriesIds, setCategoriesIds] = useState<string[]>([]);
    const [types, setTypes] = useState<IType>(defaultTypesAndTags);
    const [subtypes, setSubtypes] = useState<IType>(defaultTypesAndTags);
    const [tags, setTags] = useState<ITag>(defaultTypesAndTags);
    const [variants, setVariants] = useState({
        colors: [],
        sizes: []
    });

    const addImages = useCallback((item: IImage) => {
        setImages((oldState) => {
            return [...oldState, item];
        });
    }, []);

    const addVariant = useCallback((formRef: any) => {
        const variantType = formRef.current.getFieldValue('variant_type');
        const variantName = formRef.current.getFieldValue('variant_name');

        if (variantType && variantName) {
            formRef.current.getFieldRef('variant_name').value = '';

            let priceSale = formRef.current.getFieldValue(`${variantName}_price`);
            priceSale = Number(priceSale || 0);

            setVariants((oldState) => {
                const updatedState = oldState;

                if (variantType === 'color') {
                    updatedState.colors.push({
                        id: uuid(),
                        type: 'color',
                        image_url: '',
                        name: variantName,
                        quantity: 1,
                        price: priceSale,
                        price_type: ''
                    });
                } else {
                    updatedState.sizes.push({
                        id: uuid(),
                        type: 'size',
                        image_url: '',
                        name: variantName,
                        quantity: 1,
                        price: priceSale,
                        price_type: ''
                    });
                }

                return { ...updatedState };
            });
        }
    }, []);

    const addListVariants = useCallback((listVariants) => {
        setVariants(listVariants);
    }, []);

    const editVariant = useCallback((variantName: string, field: string, value: any) => {
        setVariants((oldState) => {
            const updatedState = oldState;

            updatedState.colors = updatedState.colors.map((color) => {
                if (color.name === variantName) {
                    return {
                        ...color,
                        [field]: value
                    };
                }

                return color;
            });

            updatedState.sizes = updatedState.sizes.map((color) => {
                if (color.name === variantName) {
                    return {
                        ...color,
                        [field]: value
                    };
                }

                return color;
            });

            return { ...oldState };
        });
    }, []);

    const removeVariants = useCallback((colorName) => {
        setVariants((oldState) => {
            const updatedState = oldState;

            updatedState.colors = updatedState.colors.filter((color) => color.name !== colorName);

            updatedState.sizes = updatedState.sizes.filter((color) => color.name !== colorName);

            return { ...updatedState };
        });
    }, []);

    const changeOrderImage = useCallback((indexDragged, indexTarget) => {
        setImages((oldState) => {
            return produce(oldState, (draft) => {
                const dragged = draft[indexDragged];

                draft.splice(indexDragged, 1);
                draft.splice(indexTarget, 0, dragged);
            });
        });
    }, []);

    const addCategories = useCallback((ids: string[]) => {
        setCategoriesIds(ids);
    }, []);

    const addTypes = useCallback((ids: string[], news: string[]) => {
        setTypes((oldState) => {
            return {
                ids: ids || oldState.ids,
                news: news || oldState.news
            };
        });
    }, []);

    const addSubtypes = useCallback((ids: string[], news: string[]) => {
        setSubtypes((oldState) => {
            return {
                ids: ids || oldState.ids,
                news: news || oldState.news
            };
        });
    }, []);

    const addTags = useCallback((ids: string[], news: string[]) => {
        setTags((oldState) => {
            return {
                ids: ids || oldState.ids,
                news: news || oldState.news
            };
        });
    }, []);

    const removeImage = useCallback((item: IImage) => {
        setImages((oldState) => {
            return oldState.filter((oldImage) => oldImage.id !== item.id);
        });
    }, []);

    const clearImages = useCallback(() => {
        setImages([]);
    }, []);

    const setCover = useCallback((item: IImage) => {
        setImages((oldState) => {
            return oldState.map((oldImage) => {
                if (oldImage.id === item.id) {
                    return { ...item, is_cover: true };
                }

                return { ...oldImage, is_cover: false };
            });
        });
    }, []);

    const getAttributes = useCallback((): IAttributes => {
        return {
            is_enable: isEnable,
            is_promotional: isPromotional,
            is_free_shipping: isFreeShipping,
            images,
            categories: categoriesIds,
            types,
            subtypes,
            tags,
            variants
        };
    }, [
        isEnable,
        isPromotional,
        isFreeShipping,
        images,
        categoriesIds,
        types,
        subtypes,
        tags,
        variants
    ]);

    return (
        <ProductFormContext.Provider
            value={{
                setEnable,
                setPromotional,
                setFreeShipping,
                addImages,
                addCategories,
                addTypes,
                addSubtypes,
                addTags,
                addVariant,
                addListVariants,
                removeImage,
                clearImages,
                editVariant,
                removeVariants,
                setCover,
                getAttributes,
                changeOrderImage
            }}
        >
            {children}
        </ProductFormContext.Provider>
    );
};

function useProductForm(): IContextData {
    const context = useContext(ProductFormContext);

    if (!context) {
        throw new Error('useProductForm must be used within a ListUploadProductImagesProvider');
    }

    return context;
}

export { useProductForm };
export default ProductFormProvider;

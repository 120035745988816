import { Button, Divider, Flex, Text, useToast } from '@chakra-ui/core';
import { FormHandles } from '@unform/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Form from '../../components/Form';
import Checkbox from '../../components/Form/Checkbox';
import Input from '../../components/Form/Input';
import { useLayout } from '../../layouts/default';
import { HTTP } from '../../shared/constants';
import apiGateway from '../../shared/services/apiGateway';
import APIBrasilHelper from '../../shared/utils/APIBrasilHelper';

const NotificationSettings: React.FC = () => {
    const { changeTitle } = useLayout();

    const [data, setData] = useState<any>();

    const formRef = useRef<FormHandles>(null);
    const toast = useToast();

    const [deviceStatus, setDeviceStatus] = useState('CONNECTED');
    const [QRCode, setQRCode] = useState('');

    const [isSendDelivery, setIsSendDelivery] = useState(false);
    const [isSendNewOrder, setIsSendNewOrder] = useState(false);
    const [isSendOrderStatus, setIsSendOrderStatus] = useState(false);

    const handleSubmit = useCallback(
        async formData => {
            if(!data.config) return;

            const response = await apiGateway.put(
                `/settings/whatsapp`,
                {
                    config: {
                        plan: data.config?.plan,
                        device_token: data.config?.device_token,
                        device_number: data.config?.device_number,
                        device_password: "",
                        send_delivery: isSendDelivery,
                        send_new_order: isSendNewOrder,
                        send_order_status: isSendOrderStatus,
                        list_numbers: formData.list_numbers.trim().split(',')
                    }
                }
            );

            if (response.status === HTTP.STATUS.SUCCESS) {
                toast({
                    title: 'Configuração Atualizada',
                    description: 'Sua configuração foi atualizada com sucesso',
                    status: 'success',
                    duration: 4000,
                    isClosable: true
                });
            }
        },

        [toast, data, isSendDelivery, isSendNewOrder, isSendOrderStatus]
    );

    useEffect(() => {
        changeTitle('Whatsapp');
        apiGateway.get('/settings/whatsapp').then(({ status, data }) => {
            if(status === 200) {
                setData(data);
                setIsSendDelivery(!!data?.config?.send_delivery);
                setIsSendNewOrder(!!data?.config?.send_new_order);
                setIsSendOrderStatus(!!data?.config?.send_order_status);

                if(data?.config?.plan === 'DEFAULT')
                    setDeviceStatus('CONNECTED');
                else {
                    try {
                        APIBrasilHelper.getQRCode(data?.config?.device_token)
                            .then(({ status, data }) => {
                                if(data.error) {
                                    console.log(data);
                                    return;
                                }

                                console.log(data?.response?.qrcode);
                                setQRCode(data?.response?.qrcode);

                                if(status !== 200) {
                                    setDeviceStatus('CONNECTED');
                                }
                            });
                    } catch (e) {
                        setDeviceStatus('CONNECTED');
                        console.log(e);
                    }
                }
            }
        });
    }, []);

    return data && (
        <Form ref={formRef as any} onSubmit={handleSubmit}>
            <Flex width="100%" flexDirection="column">
                <Flex width="100%" flexDirection="column">
                    <Flex
                        width="100%"
                        flexDirection="column"
                        backgroundColor="white"
                        px="24px"
                    >
                        <Flex
                            width="100%"
                            py="16px"
                            justifyContent="space-between"
                            flexWrap="wrap"
                        >
                            <Flex width="100%" justifyContent="space-between" flexWrap="wrap">
                                <Flex
                                    width="48%"
                                    flexDirection="column"
                                    style={{ gap: "8px" }}
                                >
                                    <Text fontSize="24px" color="purple.500" mb="8px">
                                        Configurarações do Whatsapp
                                    </Text>

                                    <Flex flexDirection="column">
                                        <Text fontSize="16px" fontWeight="600">
                                            API Sendo Utilizada
                                        </Text>

                                        <Text fontSize="16px">
                                            API Brasil
                                        </Text>
                                    </Flex>

                                    <Flex flexDirection="column">
                                        <Text fontSize="16px" fontWeight="600">
                                            Número que envia as mensagens
                                        </Text>

                                        <Flex width="200px">
                                            <Input name="send_number" isReadOnly value={data?.config?.device_number} />
                                        </Flex>
                                    </Flex>

                                    <Flex flexDirection="column">
                                        <Text fontSize="16px" fontWeight="600">
                                            ID Token
                                        </Text>

                                        <Flex width="360px">
                                            <Input name="send_number" isReadOnly value={data.config?.device_token} size="sm" />
                                        </Flex>
                                    </Flex>

                                    <Flex flexDirection="column">
                                        <Text fontSize="16px" fontWeight="600">
                                            Notificações
                                        </Text>

                                        {data?.config?.plan !== 'DEFAULT' && (
                                            <Flex flexDirection="column">
                                                <Flex style={{ gap: "8px" }} alignItems="center">
                                                    <Text fontSize="14px">Notificação de Entrega para o Cliente: </Text>
                                                    <Flex
                                                        width="100%"
                                                        justifyContent="space-between"
                                                        maxWidth="80px"
                                                        alignItems="flex-end"
                                                    >
                                                        <Checkbox
                                                            name="send_delivery"
                                                            isChecked={isSendDelivery}
                                                            onChange={() => setIsSendDelivery(!isSendDelivery)}
                                                            value="0"
                                                            mr="16px"
                                                        >
                                                            <Text fontSize="14px">Ativar</Text>
                                                        </Checkbox>
                                                    </Flex>
                                                </Flex>

                                                <Flex style={{ gap: "8px" }} alignItems="center">
                                                    <Text fontSize="14px">Notificação de Venda: </Text>
                                                    <Flex
                                                        width="100%"
                                                        justifyContent="space-between"
                                                        maxWidth="80px"
                                                        alignItems="flex-end"
                                                    >
                                                        <Checkbox
                                                            name="send_delivery"
                                                            isChecked={isSendNewOrder}
                                                            onChange={() => setIsSendNewOrder(!isSendNewOrder)}
                                                            value="0"
                                                            mr="16px"
                                                        >
                                                            <Text fontSize="14px">Ativar</Text>
                                                        </Checkbox>
                                                    </Flex>
                                                </Flex>



                                                <Flex style={{ gap: "8px" }} alignItems="center">
                                                    <Text fontSize="14px">Notificação de Status de Venda: </Text>
                                                    <Flex
                                                        width="100%"
                                                        justifyContent="space-between"
                                                        maxWidth="80px"
                                                        alignItems="flex-end"
                                                    >
                                                        <Checkbox
                                                            name="send_order_status"
                                                            isChecked={isSendOrderStatus}
                                                            onChange={() => setIsSendOrderStatus(!isSendOrderStatus)}
                                                            value="0"
                                                            mr="16px"
                                                        >
                                                            <Text fontSize="14px">Ativar</Text>
                                                        </Checkbox>
                                                    </Flex>
                                                </Flex>
                                            </Flex>
                                        )}

                                        <Flex width="100%" flexDirection="column" my="8px">
                                            <Text fontSize="16px" fontWeight="600">
                                                Notificação de novas vendas feito pelo número central
                                            </Text>

                                            <Text fontSize="14px" mb="2px">
                                                Números que irão receber as notificações de novas vendas no site, coloque quantos números precisar, separados por vírgula e sem caracteres especiais
                                            </Text>
                                            <Input name="list_numbers" defaultValue={data.config?.list_numbers?.map((item) => `${item}`)} size="sm" />
                                        </Flex>

                                        {data?.config?.plan === 'DEFAULT' && (
                                            <Flex width="100%" flexDirection="column" my="8px">
                                                <Text fontSize="16px" fontWeight="600">
                                                    Notificação de status de entregas e chatbot que vão ser feitas através do seu número para o número que o cliente informou na hora da compra
                                                </Text>

                                                <Text fontSize="14px" mb="2px" style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                                                    Seu plano não tem essa opção, <Text color="green.500">Fale com o suporte</Text>
                                                </Text>
                                            </Flex>
                                        )}

                                        <Flex width="360px" mt="16px">
                                            <Button
                                                type="submit"
                                                width="100%"
                                                backgroundColor="purple.500"
                                                py="8px"
                                                color="white"
                                                _hover={{
                                                    backgroundColor: 'green.500'
                                                }}
                                                fontSize="18px"
                                                fontWeight="500"
                                                borderRadius="2px"
                                            >
                                               Atualizar
                                            </Button>
                                        </Flex>
                                    </Flex>


                                </Flex>
                                <Flex width="48%" flexDirection="column">
                                    <Flex width="240px" justifyContent="center" alignItems="center" py="8px"
                                        color="white"
                                        backgroundColor={deviceStatus === 'DISCONNECTED' ? 'red.500' : 'green.500'}
                                        rounded="8px"
                                        mb="8px"
                                    >
                                        {`Status: ${deviceStatus === 'DISCONNECTED' ? 'Desconectado' : 'Conectado'}`}
                                    </Flex>

                                    {QRCode && (
                                        <img width="240px" height="240px" src={QRCode} />
                                    )}
                                </Flex>
                            </Flex>
                            <Divider my="0px" />
                        </Flex>
                        <Divider my="0px" />
                    </Flex>
                </Flex>
            </Flex>
        </Form>
    );
};

export default NotificationSettings;

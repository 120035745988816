import 'react-day-picker/lib/style.css';

import Axios from 'axios';
import { format } from 'date-fns';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DayModifiers } from 'react-day-picker';
import { FiArrowLeft, FiLink } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import {
    Box,
    Flex,
    Image,
    PseudoBox,
    Stack,
    Text,
    useDisclosure,
    useToast
} from '@chakra-ui/core';
import { FormHandles } from '@unform/core';

import logoSmall from '../../../../assets/logo-small.png';
import Form from '../../../../components/Form';
import List from '../../../../components/List';
import ListRow from '../../../../components/List/ListRow';
import PaymentStatusSmall from '../../../../components/PaymentStatus/small';
import { HTTP, PAY3_LINK_URL } from '../../../../shared/constants';
import FormHelper from '../../../../shared/helpers/FormHelper';
import apiGateway from '../../../../shared/services/apiGateway';
import CurrencyUtils from '../../../../shared/utils/CurrencyUtils';
import DateUtils from '../../../../shared/utils/DateUtils';
import NumberUtils from '../../../../shared/utils/NumberUtils';
import { useCashier } from '../../hooks/useCashier';
import AddressSection from './components/AddressSection';
import BuyerSection from './components/BuyerSection';
import Catalog from './components/Catalog';
import ChatList from './components/ChatList';
import DescountsFeeSection from './components/DescountsFeeSection';
import DetailsSection from './components/DetailsSection';
import FinishModal from './components/FinishModal';
import ObservationSection from './components/ObservationSection';
import PaymentMethodsSection from './components/PaymentMethodsSection';
import ProductsSection from './components/ProductsSection';
import ScheduleSection from './components/ScheduleSection';
import Checkbox from '../../../../components/Form/Checkbox';
import Input from '../../../../components/Form/Input';

const Cashier: React.FC = () => {
    const {
        getProducts,
        getTotalProducts,
        getTotalDelivery,
        getTotalDescount,
        getTaxValue,
        getAmount
    } = useCashier();

    const formRef = useRef<FormHandles>(null as any);
    const formRefCatalog = useRef<FormHandles>(null as any);

    const [finishedOrder, setFinishedOrder] = useState(null);

    const { isOpen, onOpen, onClose } = useDisclosure();

    const toast = useToast();
    const navigate = useNavigate();

    const [orders, setOrders] = useState([]);
    const [deliveryDate, setDeliveryDate] = useState(new Date());
    const [products, setProducts] = useState([] as any);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [inStore, setInStore] = useState(false);
    const [sendNotification, setSendNotification] = useState(null);
    const [identifySender, setIdentifySender] = useState(false);

    const [settingsIntegrations, setSettingsIntegrations] = useState(null);

    const handleChangeDeliveryDate = useCallback(
        (date: Date, modifiers: DayModifiers) => {
            if (modifiers.available && !modifiers.disabled) {
                setDeliveryDate(date);
            }
        },
        []
    );

    const handleChangePaymentMethod = useCallback(value => {
        setPaymentMethod(value);
    }, []);

    const handleChangeInStore = useCallback(value => {
        setInStore(value);
    }, []);

    const handleSubmit = useCallback(async () => {
        try {
            const dataSubmit = formRef.current.getData();

            if(settingsIntegrations?.whatsapp && sendNotification === null) {
                toast({
                    title: 'Notificação na Entrega',
                    description: 'Selecione a opcão de notificação',
                    status: 'error',
                    duration: 6000,
                    isClosable: true
                });
                
                return;
            }

            if(!paymentMethod) {
                toast({
                    title: 'Forma de Pagamento',
                    description: 'Selecione uma forma de pagamento',
                    status: 'error',
                    duration: 6000,
                    isClosable: true
                });
                return;
            }

            const schemaValidation = Yup.object().shape({
                buyer_name: Yup.string().required('Nome do cliente não informado'),
                buyer_telephone: Yup.string().required(
                    'Telefone do cliente não informado'
                ),
                delivery_hour: Yup.string().required('Turno de entrega não informado')
            });

            await schemaValidation.validate(dataSubmit, {
                abortEarly: false
            });

            const products = getProducts();

            const orderData = {
                delivery_date: format(deliveryDate, 'dd/MM/yyyy'),
                delivery_hour: dataSubmit.delivery_hour,
                buyer: {
                    name: dataSubmit.buyer_name,
                    document: dataSubmit.buyer_document,
                    telephone: dataSubmit.buyer_telephone,
                    email: dataSubmit.buyer_email
                },
                delivery_address: {
                    cep: dataSubmit.address_cep,
                    city: dataSubmit.address_city,
                    city_id: dataSubmit.city_id,
                    neighborhood: dataSubmit.address_neighborhood,
                    neighborhood_id: dataSubmit.neighborhood_id,
                    street: dataSubmit.address_street,
                    number: dataSubmit.address_number,
                    complement: dataSubmit.address_complement,
                    state: dataSubmit.address_state,
                    country: 'Brasil',
                    in_store: inStore
                },
                order_details: {
                    destinatary_name: dataSubmit.destinatary_name,
                    destinatary_telephone: dataSubmit.destinatary_telephone,
                    card_message: dataSubmit.card_message,
                    identify_sender: identifySender,
                    identify_name: dataSubmit.identify_name,
                    observations: dataSubmit.observations
                },

                order_products: products.map(product => {
                    return {
                        product_id: product.id,
                        quantity: product.quantity
                    };
                }),
                payment_method_type: paymentMethod,
                discount_value: NumberUtils.formatNumber(dataSubmit.discount_value),
                tax_value: NumberUtils.formatNumber(dataSubmit.tax_value),
                send_notification: sendNotification
            };

            const response = await apiGateway.post('/orders', orderData);

            if (response.status === HTTP.STATUS.CREATED) {
                toast({
                    title: 'Pedido finalizado!',
                    description: 'Seu pedido foi efetuado com sucesso',
                    status: 'success',
                    duration: 6000,
                    isClosable: true
                });

                setFinishedOrder(response.data);
                onOpen();
            } else {
                toast({
                    title: 'Tente novamente',
                    description: 'Verifique se os campos obrigatórios estão preenchidos',
                    status: 'error',
                    duration: 6000,
                    isClosable: true
                });
            }
        } catch (error) {
            console.log(error);
            if (error instanceof Yup.ValidationError) {
                FormHelper.showErrors(formRef, error, toast);
            }
        }
    }, [deliveryDate, getProducts, paymentMethod, inStore, sendNotification, settingsIntegrations, identifySender]);

    const handleCopyLink = useCallback(
        async link => {
            const store = localStorage.getItem('@eflorista:store');

            await navigator.clipboard.writeText(
                `${PAY3_LINK_URL}/${store}/${link.hash}`
            );

            toast({
                title: 'Link Copiado',
                description: 'Link de Pagamento Copiado',
                status: 'success',
                duration: 4000
            });
        },
        [toast]
    );

    const loadOrders = useCallback(() => {
        const token = localStorage.getItem('@eflorista:token');

        Axios.get(`${process.env.REACT_APP_API_URL}/orders?limit=50`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            if (response.status === HTTP.STATUS.SUCCESS) {
                setOrders(response.data);
            }
        });
    }, []);

    useEffect(() => {
        setProducts(getProducts());
        loadOrders();
        apiGateway('/settings/integrations')
            .then((response) => {
                const { status, data } = response;
                if(status === HTTP.STATUS.SUCCESS){
                    setSettingsIntegrations(data);
                }
            });
    }, [getProducts]);

    const TransactionRow = ({ style, index, data }: any) => {
        return (
            <ListRow style={style} index={index}>
                <Flex
                    width="100%"
                    fontSize="12px"
                    px="8px"
                    py="4px"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Flex flexDirection="column" width="40px" mr="12px">
                        <PseudoBox
                            color="purple.500"
                            _hover={{
                                color: 'green.500'
                            }}
                        >
                            <Text fontWeight="600" cursor="pointer" width="64px">
                                {data[index].code}
                            </Text>
                        </PseudoBox>
                    </Flex>
                    <Text width="144px" overflow="hidden" whiteSpace={'pre-wrap'}>
                        {`${data[index].buyer.name}`}
                    </Text>
                    <Text width="64px" fontWeight="600">
                        {CurrencyUtils.numberToCurrency(data[index].total_value)}
                    </Text>

                    <Flex width="80px">
                        <PaymentStatusSmall
                            orderId={data[index].id}
                            defaultStatus={data[index].payment_status}
                            isChangeable={false}
                        />
                    </Flex>

                    <Flex justifyContent="center" px="8px" position="relative">
                        {data[index].payment_method.name === 'Via Link' &&
              data[index].payment_status !== 'APROVADO' && (
                            <Flex
                                backgroundColor="purple.500"
                                alignItems="center"
                                color="white"
                                p="6px"
                                cursor="pointer"
                                onClick={() => handleCopyLink(data[index].link)}
                            >
                                <FiLink size={18} />
                            </Flex>
                        )}
                    </Flex>

                    <Flex flexDirection="column">
                        <Text color="purple.500" cursor="pointer" width="56px">
                            {data[index].updated_at
                                ? `${DateUtils.format(data[index].updated_at, 'HH:mm dd/MM')}`
                                : 'Sem Data'}
                        </Text>
                    </Flex>
                </Flex>
            </ListRow>
        );
    };

    return (
        <Flex width={'100%'}>
            <Flex
                width={'80px'}
                minHeight="100vh"
                flexDirection={'column'}
                backgroundColor={'purple.500'}
                position="fixed"
            >
                <Box p="8px">
                    <Image src={logoSmall} />
                </Box>
            </Flex>

            <Flex ml="80px" width={'100%'} flexDirection="column">
                <Flex
                    position={'fixed'}
                    width={'calc(100% - 80px)'}
                    height="48px"
                    justifyContent={'flex-end'}
                    alignItems="center"
                    backgroundColor="gray.200"
                    px="16px"
                    zIndex={10000}
                >
                    <Flex
                        backgroundColor={'purple.500'}
                        justifyContent="center"
                        alignItems="center"
                        px="8px"
                        py="4px"
                        color="white"
                        cursor={'pointer'}
                        onClick={() => navigate('/dashboard')}
                    >
                        <FiArrowLeft size={16} />

                        <Text ml="8px" fontSize={'14px'} fontWeight={'500'}>
              Sair do Caixa
                        </Text>
                    </Flex>
                </Flex>

                <Flex mt="48px">
                    <Form ref={formRef as any} onSubmit={() => null}>
                        <Flex width="100%" height={'calc(100vh - 48px)'}>
                            <Flex
                                width={'80%'}
                                height="100%"
                                flexDirection="column"
                                border="1px solid"
                                borderColor="gray.500"
                                px="24px"
                                py="16px"
                            >
                                <Flex width={'100%'} flexDirection="column" height="100%">
                                    <Flex
                                        width={'100%'}
                                        height="100%"
                                        justifyContent="space-between"
                                        fontSize={'14px'}
                                    >
                                        <Flex width={'48%'} height="100%" flexDirection="column">
                                            <BuyerSection formRef={formRef} />

                                            {settingsIntegrations?.whatsapp && (
                                                <Stack width="100%" direction="row" fontSize="16px" my="8px">
                                                    <Text>Enviar notificação whatsapp na entrega</Text>
                                                    <Checkbox 
                                                        isChecked={sendNotification}
                                                        onChange={() => setSendNotification(true)}
                                                        size="sm"
                                                    >
                                                    Sim
                                                    </Checkbox>
                                                    <Checkbox 
                                                        isChecked={sendNotification === false && sendNotification !== null}
                                                        onChange={() => setSendNotification(false)}
                                                        size="sm"
                                                    >
                                                        Não
                                                    </Checkbox>
                                                </Stack>
                                            )}
                                            
                                            <AddressSection
                                                formRef={formRef}
                                                handleSetInStore={handleChangeInStore}
                                            />

                                            <DetailsSection formRef={formRef} />

                                            <ScheduleSection
                                                deliveryDate={deliveryDate}
                                                handleChangeDeliveryDate={handleChangeDeliveryDate}
                                            />

                                            <ProductsSection
                                                formRef={formRefCatalog}
                                                products={products}
                                            />

                                            <DescountsFeeSection />

                                            <PaymentMethodsSection
                                                paymentMethod={paymentMethod}
                                                handlePaymentMethod={handleChangePaymentMethod}
                                            />

                                            <ObservationSection />

                                            <Stack width="100%" direction="row" fontSize="16px" my="8px">
                                                <Checkbox 
                                                    isChecked={identifySender}
                                                    onChange={() => setIdentifySender(!identifySender)}
                                                    size="md"
                                                >
                                                    <Text fontWeight="500">Identificar Remetente</Text>
                                                </Checkbox>
                                            </Stack>

                                            {identifySender && (
                                                <Input
                                                    name="identify_name"
                                                    backgroundColor="gray.500"
                                                    placeholder="Nome de Identificação"
                                                    borderRadius="2px"
                                                    size="sm"
                                                    mb="8px"
                                                />
                                            )}

                                            <Flex
                                                mt={'auto'}
                                                width={'100%'}
                                                justifyContent="space-between"
                                                alignItems={'center'}
                                                color="white"
                                                fontWeight={'500'}
                                                fontSize={'20px'}
                                                flexDirection="column"
                                            >
                                                <Flex
                                                    width={'100%'}
                                                    backgroundColor="blue.100"
                                                    flexDirection={'column'}
                                                    px="8px"
                                                    py="4px"
                                                    fontSize={'14px'}
                                                    color="gray.600"
                                                    mb="8px"
                                                >
                                                    <Flex width={'100%'} justifyContent="space-between">
                                                        <Text>Total Produtos</Text>
                                                        <Text fontWeight={'500'}>
                                                            {CurrencyUtils.numberToCurrency(
                                                                getTotalProducts()
                                                            )}
                                                        </Text>
                                                    </Flex>

                                                    <Flex width={'100%'} justifyContent="space-between">
                                                        <Text>Valor de Entrega</Text>
                                                        <Text fontWeight={'500'}>
                                                            {CurrencyUtils.numberToCurrency(
                                                                getTotalDelivery()
                                                            )}
                                                        </Text>
                                                    </Flex>

                                                    <Flex width={'100%'} justifyContent="space-between">
                                                        <Text>Acréscimos</Text>
                                                        <Text fontWeight={'500'}>
                                                            {CurrencyUtils.numberToCurrency(getTaxValue())}
                                                        </Text>
                                                    </Flex>

                                                    <Flex width={'100%'} justifyContent="space-between">
                                                        <Text>Desconto</Text>
                                                        <Text fontWeight={'500'}>
                                                            {CurrencyUtils.numberToCurrency(
                                                                getTotalDescount()
                                                            )}
                                                        </Text>
                                                    </Flex>

                                                    <Flex
                                                        width={'100%'}
                                                        fontSize="20px"
                                                        justifyContent="space-between"
                                                    >
                                                        <Text>Total a Pagar</Text>
                                                        <Text fontWeight={'500'}>
                                                            {CurrencyUtils.numberToCurrency(getAmount())}
                                                        </Text>
                                                    </Flex>
                                                </Flex>
                                                <Flex
                                                    width={'100%'}
                                                    justifyContent="space-between"
                                                    color={'white'}
                                                >
                                                    <Flex
                                                        width={'48%'}
                                                        height="40px"
                                                        justifyContent={'center'}
                                                        alignItems={'center'}
                                                        backgroundColor="green.500"
                                                        cursor={'pointer'}
                                                        onClick={() => navigate(0)}
                                                    >
                                                        <Text>Novo Pedido</Text>
                                                    </Flex>

                                                    <Flex
                                                        width={'48%'}
                                                        height="40px"
                                                        justifyContent="center"
                                                        alignItems={'center'}
                                                        backgroundColor="purple.500"
                                                        cursor={'pointer'}
                                                        onClick={() => handleSubmit()}
                                                    >
                                                        <Text>Finalizar</Text>
                                                    </Flex>
                                                </Flex>
                                            </Flex>
                                        </Flex>

                                        <Flex width={'48%'} height="100%" flexDirection="column">
                                            <Flex
                                                width={'100%'}
                                                minHeight="50%"
                                                height="50%"
                                                flexDirection={'column'}
                                            >
                                                <Catalog formRef={formRefCatalog} />
                                            </Flex>

                                            <Flex
                                                width={'100%'}
                                                minHeight="50%"
                                                maxHeight="50%"
                                                height={'auto'}
                                                flexDirection={'column'}
                                            >
                                                <Flex
                                                    width="100%"
                                                    height="100%"
                                                    flexDirection="column"
                                                    border="1px solid"
                                                    borderColor="gray.100"
                                                    overflow="auto"
                                                >
                                                    <Flex
                                                        width="100%"
                                                        flexDirection="column"
                                                        py="8px"
                                                        px="16px"
                                                    >
                                                        <Text
                                                            color="purple.500"
                                                            fontSize="20px"
                                                            mb="8px"
                                                            fontWeight={'500'}
                                                        >
                              Últimas vendas
                                                        </Text>
                                                    </Flex>

                                                    <Flex
                                                        width="100%"
                                                        flexDirection="column"
                                                        overflow="auto"
                                                        overflowX={'hidden'}
                                                    >
                                                        {orders.length > 0 && (
                                                            <List
                                                                item={orders}
                                                                row={TransactionRow}
                                                                itemHeight={48}
                                                            />
                                                        )}
                                                    </Flex>
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </Flex>
                            <ChatList />
                        </Flex>
                    </Form>
                </Flex>
            </Flex>

            {finishedOrder && (
                <FinishModal isOpen={isOpen} onClose={onClose} order={finishedOrder} />
            )}
        </Flex>
    );
};

export default Cashier;

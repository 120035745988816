import React from 'react';

import { Collapse, Flex, Text } from '@chakra-ui/core';

import Checkbox from '../../../../../../components/Form/Checkbox';
import CollapseCheckoutBox from '../../../../components/CollapseCheckoutBox';
import { useCashier } from '../../../../hooks/useCashier';

interface IProps {
  paymentMethod: any;
  handlePaymentMethod: (value: string) => void;
}

const PaymentMethodsSection: React.FC<IProps> = ({
    handlePaymentMethod,
    paymentMethod
}) => {
    const { getStateBox, collapseBox } = useCashier();

    return (
        <Flex width={'100%'} flexDirection="column">
            <CollapseCheckoutBox
                title="Forma de Pagamento"
                isCollapsed={getStateBox('PAYMENT_METHOD')}
                onCollapse={() => collapseBox('PAYMENT_METHOD')}
                isComplete={!!paymentMethod}
            />

            <Collapse
                width="100%"
                mt="4px"
                isOpen={getStateBox('PAYMENT_METHOD')}
                display="flex"
                flexDirection="column"
            >
                <Flex width="100%" px="8px" flexWrap={'wrap'}>
                    <Checkbox
                        name="is_enable"
                        isChecked={paymentMethod === 'CREDITCARD'}
                        onChange={() => handlePaymentMethod('CREDITCARD')}
                        value="0"
                        size="md"
                        mr="16px"
                    >
                        <Text fontSize="14px" width="100%">
                            Cartão
                        </Text>
                    </Checkbox>

                    <Checkbox
                        name="is_enable"
                        isChecked={paymentMethod === 'MONEY'}
                        onChange={() => handlePaymentMethod('MONEY')}
                        value="0"
                        size="md"
                        mr="16px"
                    >
                        <Text fontSize="14px" width="100%">
                            Dinheiro
                        </Text>
                    </Checkbox>

                    <Checkbox
                        name="is_enable"
                        isChecked={paymentMethod === 'LINK'}
                        onChange={() => handlePaymentMethod('LINK')}
                        value="1"
                        size="md"
                        mr="16px"
                    >
                        <Text fontSize="14px">Link</Text>
                    </Checkbox>

                    <Checkbox
                        name="is_enable"
                        isChecked={paymentMethod === 'DEPOSIT'}
                        onChange={() => handlePaymentMethod('DEPOSIT')}
                        value="1"
                        size="md"
                        mr="16px"
                    >
                        <Text fontSize="14px">Transferência/Pix</Text>
                    </Checkbox>

                    <Checkbox
                        name="is_enable"
                        isChecked={paymentMethod === 'PAY_IN_STORE'}
                        onChange={() => handlePaymentMethod('PAY_IN_STORE')}
                        value="1"
                        size="md"
                        mr="16px"
                    >
                        <Text fontSize="14px">Pagamento na Loja</Text>
                    </Checkbox>
                </Flex>
            </Collapse>
        </Flex>
    );
};

export default PaymentMethodsSection;

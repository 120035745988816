import { motion, Variants } from 'framer-motion';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BiCalculator, BiSitemap } from 'react-icons/bi';
import {
    FiAlignJustify,
    FiArrowLeft,
    FiBookmark,
    FiCreditCard,
    FiGrid,
    FiImage,
    FiLayout,
    FiSettings,
    FiShoppingBag,
    FiShoppingCart,
    FiSliders,
    FiTrendingUp,
    FiUserCheck
} from 'react-icons/fi';
import { TbFlower } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';

import { Badge, Flex, Image, PseudoBox, Text } from '@chakra-ui/core';

import { MdOutlineNotificationsActive } from 'react-icons/md';
import logoSmall from '../../assets/logo-small.png';
import logo from '../../assets/logo-white.png';
import { useLayout } from '../../layouts/default';
import { useAuth } from '../../shared/hooks/useAuth';

const SideMenu: React.FC = () => {
    const navigate = useNavigate();
    const { isExpandedMenu, collapseOrExpand } = useLayout();
    const [isMenuSettings, setMenuSettings] = useState(false);

    const handleChangeMenu = useCallback(() => {
        setMenuSettings((oldState) => !oldState);
    }, []);

    const [options, setOptions] = useState([]);

    const variantsMenu: Variants = {
        expanded: {
            width: '250px'
        },
        collapsed: {
            width: '80px'
        }
    };

    const variantsOptions: Variants = {
        expanded: {
            marginLeft: '24px'
        },
        collapsed: {
            marginLeft: '24px'
        }
    };

    const variantsOptionsText: Variants = {
        expanded: {
            opacity: 1,
            x: 0
        },
        collapsed: {
            opacity: 0,
            x: '-200%'
        }
    };

    const { store_name, user } = useAuth();

    useEffect(() => {
        setOptions(() => {
            if (isMenuSettings) {
                return [
                    {
                        is_parent: true,
                        name: 'Canais de Venda',
                        route: '/categories',
                        icon: <BiSitemap size={18} />,
                        suboptions: [
                            {
                                name: 'Meus Canais',
                                route: '/channels'
                            }
                        ]
                    },
                    {
                        is_parent: true,
                        name: 'Configurações',
                        route: '/dashboard',
                        icon: <FiSliders size={18} />,
                        suboptions: [
                            {
                                name: 'Gerais',
                                route: '/settings/general'
                            },
                            {
                                name: 'Cupons',
                                route: '/settings/cupons'
                            },
                            {
                                name: 'Cidades',
                                route: '/settings/cities'
                            },
                            {
                                name: 'Horários',
                                route: '/settings/schedules'
                            }
                        ]
                    },
                    // {
                    //     iscoming: true,
                    //     is_parent: true,
                    //     name: 'Estoque',
                    //     route: '/categories',
                    //     icon: <FiPackage size={18} />,
                    //     suboptions: [
                    //         {
                    //             name: 'Ajustar estoque',
                    //             route: '/dashboard',
                    //             icon: <FiGrid size={18} />
                    //         },
                    //         {
                    //             name: 'Relatorio de estoque',
                    //             route: '/dashboard',
                    //             icon: <FiGrid size={18} />
                    //         }
                    //     ]
                    // },
                    // {
                    //     is_parent: true,
                    //     name: 'Integrações',
                    //     route: '/integrations',
                    //     icon: <FiCode size={18} />,
                    //     suboptions: [
                    //         {
                    //             name: 'Tags e Integrações',
                    //             route: '/integrations/analytics_chat',
                    //             icon: <FiGrid size={18} />
                    //         }
                    //     ]
                    // },
                    {
                        is_parent: true,
                        name: 'Pagamento e Frete',
                        route: '/categories',
                        icon: <FiCreditCard size={18} />,
                        suboptions: [
                            {
                                name: 'Métodos de Entrega',
                                route: '/settings/delivery_methods',
                                icon: <FiGrid size={18} />
                            },
                            {
                                name: 'Métodos de Pagamento',
                                route: '/settings/payment_methods',
                                icon: <FiGrid size={18} />
                            }
                        ]
                    },
                    {
                        is_parent: true,
                        name: 'Personalizar',
                        route: '/categories',
                        icon: <FiLayout size={18} />,
                        suboptions: [
                            {
                                name: 'Home',
                                route: '/settings/sets',
                                icon: <FiGrid size={18} />
                            },
                            {
                                name: 'Integrações',
                                route: '/settings/integrations',
                                icon: <FiGrid size={18} />
                            },
                            {
                                name: 'Menus',
                                route: '/settings/menu',
                                icon: <FiGrid size={18} />
                            },
                            {
                                name: 'Paginas',
                                route: '/settings/pages',
                                icon: <FiGrid size={18} />
                            },
                            {
                                name: 'Serviços',
                                route: '/settings/services',
                                icon: <FiGrid size={18} />
                            },
                            {
                                name: 'Site',
                                route: '/settings/site',
                                icon: <FiGrid size={18} />
                            }
                        ]
                    },
                    {
                        is_parent: true,
                        name: 'Usuários',
                        route: '/categories',
                        icon: <FiUserCheck size={18} />,
                        suboptions: [
                            {
                                name: 'Lista de Usuários',
                                route: '/settings/users',
                                icon: <FiGrid size={18} />
                            },
                            {
                                name: 'Entregadores',
                                route: '/settings/users/deliverymens',
                                icon: <FiGrid size={18} />
                            }
                        ]
                    }
                ];
            }

            return [
                {
                    name: 'Dashboard',
                    route: '/dashboard',
                    icon: <FiGrid size={18} />
                },
                {
                    name: 'Banners',
                    route: '/banners',
                    icon: <FiImage size={18} />
                },
                {
                    name: 'Venda',
                    route: '/cashier',
                    icon: <BiCalculator size={18} />,
                    iscoming: false,
                    new_tab: true
                },
                {
                    name: 'Categorias',
                    route: '/categories',
                    icon: <FiBookmark size={18} />
                },
                // {
                //   name: 'Clientes',
                //   route: '/customers',
                //   icon: <FiUsers size={18} />
                // },
                {
                    name: 'Whatsapp',
                    route: '/notifications',
                    icon: <MdOutlineNotificationsActive size={18} />,
                    plus: true
                },
                {
                    name: 'Produtos',
                    route: '/products',
                    icon: <FiShoppingBag size={18} />
                },

                {
                    name: 'Relatórios',
                    route: '/relatories',
                    icon: <FiTrendingUp size={18} />
                },
                {
                    name: 'Pedidos',
                    route: '/orders',
                    icon: <FiShoppingCart size={18} />
                },
                {
                    name: 'Painel Florista',
                    route: '/orders/production',
                    icon: <TbFlower size={18} />
                }
            ];
        });
    }, [isMenuSettings]);

    const menuOptions = useMemo(() => {
        return options.filter((option) => {
            if (!user.permission || user.permission === 'Admin') {
                return option;
            } else {
                return option.name !== 'Relatórios';
            }
        });
    }, [options, user]);

    return (
        <Flex
            height="100%"
            backgroundColor="purple.500"
            flexDirection="column"
            color="white"
            top="0"
            left="0"
            position="fixed"
            zIndex={100000000}
        >
            <PseudoBox
                color="purple.500"
                borderRadius="2px"
                position="absolute"
                top="12px"
                display="flex"
                alignItems="flex-start"
                height="100%"
                right="-48px"
                cursor="pointer"
                onClick={() => collapseOrExpand()}
            >
                {isExpandedMenu && <FiAlignJustify size={18} />}
                {!isExpandedMenu && <FiAlignJustify size={18} />}
            </PseudoBox>
            <motion.div
                style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}
                initial="expanded"
                animate={isExpandedMenu ? 'expanded' : 'collapsed'}
                variants={variantsMenu}
            >
                <Flex width="100%" flexDirection="column">
                    <Flex
                        width="100%"
                        height="100%"
                        alignItems="center"
                        justifyContent="center"
                        py="8px"
                    >
                        {isExpandedMenu && <Image src={logo} height="56px" />}
                        {!isExpandedMenu && <Image src={logoSmall} height="32px" />}
                    </Flex>
                </Flex>

                <Flex
                    width="100%"
                    height="48px"
                    color="white"
                    py="4px"
                    backgroundColor="purple.700"
                    alignItems="center"
                    justifyContent="center"
                >
                    {isExpandedMenu && (
                        <Flex
                            width="100%"
                            justifyContent="center"
                            alignItems="center"
                            fontSize="14px"
                            color="purple.500"
                            backgroundColor="yellow.500"
                            py="4px"
                            position="relative"
                        >
                            <Text>{store_name}</Text>

                            {isMenuSettings && (
                                <motion.div
                                    style={{
                                        display: 'flex',
                                        cursor: 'pointer',
                                        position: 'absolute',
                                        right: '8px'
                                    }}
                                    initial="expanded"
                                    animate={isExpandedMenu ? 'expanded' : 'collapsed'}
                                    variants={variantsOptions}
                                    onClick={() => handleChangeMenu()}
                                >
                                    <FiArrowLeft size={18} />
                                </motion.div>
                            )}
                        </Flex>
                    )}
                </Flex>

                <Flex
                    width="100%"
                    height="100%"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    {menuOptions.map((option) => (
                        <Flex key={option.name} width="100%" flexDirection="column" py="4px">
                            <PseudoBox
                                display="flex"
                                width="100%"
                                onClick={() => {
                                    if (option.is_parent || option?.iscoming) {
                                        return undefined;
                                    }

                                    if (option?.new_tab) {
                                        window.open(option.route, '_blank');
                                        return;
                                    }

                                    return navigate(option.route);
                                }}
                                alignItems="center"
                                cursor={
                                    option.is_parent || option?.iscoming ? 'default' : 'pointer'
                                }
                                _hover={{
                                    color:
                                        option.is_parent || option?.iscoming ? 'white' : 'green.500'
                                }}
                            >
                                <motion.div
                                    style={{
                                        display: 'flex'
                                    }}
                                    initial="expanded"
                                    animate={isExpandedMenu ? 'expanded' : 'collapsed'}
                                    variants={variantsOptions}
                                >
                                    {option.icon}
                                </motion.div>

                                <motion.div
                                    initial="expanded"
                                    animate={isExpandedMenu ? 'expanded' : 'collapsed'}
                                    variants={variantsOptionsText}
                                >
                                    <Flex width="100%" alignItems="center">
                                        <Text ml="16px" fontSize="14px" lineHeight="14px" mr="8px">
                                            {option.name}
                                        </Text>
                                        {option?.iscoming && (
                                            <Badge
                                                backgroundColor="yellow.500"
                                                fontSize="10px"
                                                pt="2px"
                                            >
                                                EM BREVE
                                            </Badge>
                                        )}
                                        {option?.plus && (
                                            <Badge
                                                backgroundColor="purple.700"
                                                fontSize="10px"
                                                pt="2px"
                                                color="white"
                                                px="6px"
                                            >
                                                PLUS
                                            </Badge>
                                        )}
                                    </Flex>
                                </motion.div>
                            </PseudoBox>
                            {option.is_parent && (
                                <Flex width="100%" flexDirection="column" mb="8px">
                                    {option.suboptions.map((suboption) => (
                                        <PseudoBox
                                            key={suboption.name}
                                            display="flex"
                                            width="100%"
                                            pt="4px"
                                            onClick={() => {
                                                return navigate(suboption.route);
                                            }}
                                            _hover={{
                                                color: option?.iscoming ? 'white' : 'green.500',
                                                cursor: option?.iscoming ? 'default' : 'pointer'
                                            }}
                                        >
                                            <motion.div
                                                initial="expanded"
                                                animate={isExpandedMenu ? 'expanded' : 'collapsed'}
                                                variants={variantsOptionsText}
                                            >
                                                <Text ml="64px" fontSize="12px" lineHeight="14px">
                                                    {suboption.name}
                                                </Text>
                                            </motion.div>
                                        </PseudoBox>
                                    ))}
                                </Flex>
                            )}
                        </Flex>
                    ))}
                </Flex>
                <Flex width="100%" flexDirection="column" py="24px">
                    <PseudoBox
                        display="flex"
                        width="100%"
                        onClick={() => handleChangeMenu()}
                        alignItems="center"
                        cursor="pointer"
                        _hover={{
                            color: 'green.500'
                        }}
                    >
                        {!isMenuSettings && (
                            <>
                                <motion.div
                                    style={{
                                        display: 'flex'
                                    }}
                                    initial="expanded"
                                    animate={isExpandedMenu ? 'expanded' : 'collapsed'}
                                    variants={variantsOptions}
                                >
                                    <FiSettings size={18} />
                                </motion.div>

                                <motion.div
                                    initial="expanded"
                                    animate={isExpandedMenu ? 'expanded' : 'collapsed'}
                                    variants={variantsOptionsText}
                                >
                                    <Text ml="16px" fontSize="14px" lineHeight="18px">
                                        Configurações
                                    </Text>
                                </motion.div>
                            </>
                        )}

                        {isMenuSettings && (
                            <>
                                <motion.div
                                    style={{
                                        display: 'flex'
                                    }}
                                    initial="expanded"
                                    animate={isExpandedMenu ? 'expanded' : 'collapsed'}
                                    variants={variantsOptions}
                                >
                                    <FiArrowLeft size={18} />
                                </motion.div>

                                <motion.div
                                    initial="expanded"
                                    animate={isExpandedMenu ? 'expanded' : 'collapsed'}
                                    variants={variantsOptionsText}
                                >
                                    <Text ml="16px" fontSize="14px" lineHeight="18px">
                                        Voltar
                                    </Text>
                                </motion.div>
                            </>
                        )}
                    </PseudoBox>
                </Flex>
                <Flex
                    mt="auto"
                    width="100%"
                    backgroundColor="purple.700"
                    color="white"
                    py="4px"
                    boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
                >
                    {isExpandedMenu && (
                        <Flex
                            width="100%"
                            justifyContent="center"
                            alignItems="center"
                            fontSize="12px"
                        >
                            <Text mr="8px">Versão Beta</Text>
                            <Text>0.6.5</Text>
                        </Flex>
                    )}
                </Flex>
            </motion.div>
        </Flex>
    );
};

export default SideMenu;
